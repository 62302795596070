import Typography from "typography"
// import Wordpress2016 from "typography-theme-wordpress-2016"
// import Wordpress2014 from "typography-theme-wordpress-2014"
// import funstonTheme from 'typography-theme-funston'
import deYoungTheme from 'typography-theme-de-young'

// Wordpress2016.overrideThemeStyles = () => {
//   return {
//     "a.gatsby-resp-image-link": {
//       boxShadow: `none`,
//     },
//   }
// }

// delete Wordpress2016.googleFonts
// Wordpress2014.baseFontSize = '16px'
// Wordpress2014.headeFontFamily = ['Kaushan Script', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', 'sans-serif']

const typography = new Typography(deYoungTheme)


// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
